<template>
  <div>
    <ListModel
      :searchData="searchData"
      :tableData="tableData"
      :tableCols="tableCols"
      :formInline="formInline"
      :total="total"
      :pageSize="pageSize"
      :loading="loading"
      :pageNum.sync="page"
      :tableOpt="tableOpt"
      :searchOpt="searchOpt"
    >
      <template slot="searchCriteria">
        <el-form-item :label="$t('searchModule.Role_Name')">
          <el-input
            v-model.trim="formInline.monitorManagerRoleName"
            style="width: 180px"
          ></el-input>
        </el-form-item>
      </template>
    </ListModel>
  </div>
</template>

<script>
import ListModel from "@/components/listModel/listModel";
export default {
  name: "customerRole",
  data() {
    return {
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "monitorManagerRoleName",
          label: "角色名称",
        },
        {
          prop: "systemType",
          label: "所属平台",
          width: "",
          formatter: (row) => {
            return "监管平台";
          },
        },
        {
          prop: "description",
          label: "备注",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
          width: "",
        },
      ],
      // 搜索区域按钮
      searchOpt: [
        {
          type: "primary", // primary / success / warning / danger / info / text
          icon: "el-icon-search",
          label: "查询",
          loading: true,
          show: true,
          func: () => {
            this.page = 1;
            this.searchData();
          },
        },
        {
          type: "primary", // primary / success / warning / danger / info / text
          icon: "el-icon-plus",
          label: "添加",
          show: this.$route.meta.authority.button.add,
          // func: this.add
          func: () => {
            this.$router.push("/customerRole-AE");
          },
        },
      ],
      tableOpt: {
        label: this.$t("list.operation"),
        width: "",
        type: "dropdown",
        show: true,
        operations: [
          {
            label: "编辑",
            show: this.$route.meta.authority.button.edit,
            func: (scope) => {
              this.$router.push({
                path: "/customerRole-AE",
                query: scope.row,
              });
            },
          },
          {
            label: "删除",
            show: this.$route.meta.authority.button.delete,
            func: this.delData,
          },
        ],
      },
      tableData: [],
      formInline: {
        monitorManagerRoleName: "",
      },
    };
  },
  activated() {
    this.searchData();
  },
  methods: {
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/monitorManagerRole/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    delData({ row }) {
      this.$confirm("此操作将永久删除该功能, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/monitorManagerRole/delete/" + row.monitorManagerRoleId)
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
              this.page = 1;
              this.searchData();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeDestroy() {},
  created() {},
  components: {
    ListModel,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

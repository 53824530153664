import { render, staticRenderFns } from "./customerRole.vue?vue&type=template&id=caa4e37a"
import script from "./customerRole.vue?vue&type=script&lang=js"
export * from "./customerRole.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/ACP-PRE/aiparkcity_acb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('caa4e37a')) {
      api.createRecord('caa4e37a', component.options)
    } else {
      api.reload('caa4e37a', component.options)
    }
    module.hot.accept("./customerRole.vue?vue&type=template&id=caa4e37a", function () {
      api.rerender('caa4e37a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/backstageOperation/roleManage/customerRole.vue"
export default component.exports